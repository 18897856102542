.flashing {
  -webkit-animation: flash linear 5s infinite;
  animation: flash linear 5s infinite;
  text-shadow: 0 0 5px #fff, 0 0 10px #d419ac, 0 0 15px #8251fd,
    0 0 20px #8251fd, 0 0 25px #8251fd, 0 0 30px #d419ac, 0 0 35px #8251fd;
}
/* Hero Section Icons */
.icono .s-i svg {
  width: 80px;
  transition: 0.5s ease;
}
.icono .s-i:first-child svg {
  -webkit-animation: breathing 1.9s ease-out 1s infinite normal;

  animation: breathing 1.9s ease-out infinite 1s normal;
}
.icono .s-i:nth-child(2) svg {
  -webkit-animation: breathing 1.9s ease-out 1.3s infinite normal;
  animation: breathing 1.9s ease-out 1.3s infinite normal;
}
.icono .s-i:last-child svg {
  -webkit-animation: breathing 1.9s ease-out1s 1.6s infinite normal;
  animation: breathing 1.9s ease-out 1.6s infinite normal;
}

.icono .s-i:last-child svg,
.icono .s-i:first-child svg {
  width: 50px;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9) translate(45deg);
    transform: scale(0.9) translate(45deg);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #e1e1e1 !important;
  text-align: left;
  text-decoration: none;
  background-color: #1b0d2e !important;
  border-radius: 3px;
  min-height: 34px;
  border: 1px solid #f7f7f745 !important;
}
.rc-tooltip {
  max-width: 200px;

  opacity: 1 !important;
}
.pagination-btn.selected {
  background: rgba(167, 34, 244, 1);
}
.text-clip {
  display: -webkit-box;
  height: 5.6em;
  overflow: hidden;
  line-height: 1.4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
@media (max-width: 1000px) {
  .mobile-menu {
    flex-direction: column;
    position: fixed;
    background: #1b0d2e;
    width: 100%;
    max-width: 350px;
    min-height: 100vh;
    top: 0;
    left: 0;
    display: none;
  }
  .mobile-menu li:not(:first-child) {
    padding: 0.5rem 1rem;
  }
  .menu-opener {
    display: block !important;
  }
  .logo-ancher {
    position: absolute;
    left: 50%;
    transform: translateX(-53%);
  }
}
/* loading */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brands-silder-container svg {
  height: 40px;
  width: auto;
  max-width: 100%;
}
/* .brands-silder-container svg {
  fill: #fff;
} */
.knclogo svg,
.guccilogo svg,
.pradalogo svg switch {
  fill: #fff;
  stroke: #fff;
}
.knclogo svg,
.pradalogo svg,
.guccilogo svg switch {
  height: 30px;
  max-width: 100%;
}
