@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins', sans-serif;
  color: #fff;
}
/* Select CSS */
.select__control {
  background: #1b0d2e9c !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  height: 40px !important;
}
.select__control--is-focused {
  box-shadow: none !important;
  border: 1px solid rgba(212, 25, 171, 0.603) !important;
}
.select__placeholder {
  color: rgb(199, 199, 199) !important;
}
.select__multi-value,
.select__multi-value__label {
  background: rgba(27, 13, 46, 0.747) !important;
  color: #f7f7f7 !important;
}
.select__menu {
  background: #260c33 !important;
}

.select__option:hover {
  background-color: #a722f4 !important;
}
.select__input-container .select__input,
.select__single-value,
.searchWrapper input,
.searchWrapper input::placeholder {
  color: rgb(199, 199, 199) !important;
  font-size: 14px !important;
}
.select__indicator-separator {
  display: none !important;
}
.searchWrapper {
  background: #1b0d2e9c !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.optionListContainer .notFound {
  text-align: center;
  font-size: 13px;
  color: #e2e2e2;
}
.optionListContainer .option:hover {
  background-color: #dfb6ff;
}
.optionListContainer .option.selected {
  background: #a722f4;
}
.optionListContainer .option.highlightOption:not(.selected) {
  background: transparent;
}
.search-wrapper .chip {
  background: #ab2090 !important;
}
.searchWrapper input {
  padding-left: 3px;
  width: 100%;
}
.simple-select .chip {
  background: transparent !important;
  padding: 0 !important;
  padding-left: 7px !important;
  color: rgb(199, 199, 199) !important;
}
.simple-select .closeIcon {
  display: none !important;
}
/* hanlde scrollbar */
/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar-but-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar-but-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* loader */
.love-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  /* transform-origin: 40px 40px; */
}
.love-loader svg {
  transform-origin: center;
  animation: love-loader 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes love-loader {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
